import React from 'react';
import '../styles/ReviewPage.css';
import { Box } from '@mui/material';
import contract from '../data/contracts/stories.json';
import Factory from '../Factory';

function ReviewPage() {
  return (
    <div className="review">
      <Box>
        <Factory contract={contract} />
      </Box>
    </div>
  );
}
export default ReviewPage;
