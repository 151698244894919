import { Button, Grow } from '@mui/material';
import { Link } from 'react-router-dom';
import '../styles/About.css';

function About() {
  return (
    <Grow in timeout={500}>
      <div className="about">
        <div className="row">
          <h1>1000 dives</h1>
        </div>
        <div className="row">
          Es una plataforma que centraliza la búsqueda y reservas en buceo, en la cual las buzos
          encontraran información de valor sobre los destinos solicitados y así planeen sus viajes.
          <br />
          1000 Dives es una bitácora de viaje, en la cual se cuentan historias de los principales
          sitios de buceo a través de la generación de contenido audiovisual, inspirando a los buzos
          para que visiten los territorios y tomen decisiones acertadas frente a las diferentes
          experiencias que pueden encontrar en los sitios a visitar.
        </div>
        <div className="row registration-button">
          <Link to="/signup">
            <Button variant="contained">Regístrate</Button>
          </Link>
        </div>
      </div>
    </Grow>
  );
}

export default About;
