import React from 'react';
import '../styles/NavBar.css';
import { NavLink } from 'react-router-dom';
import { Tab } from '@mui/material';
import logo from '../images/logo.svg';

function NavBar() {
  const getLinkClass = ({ isActive }) => {
    const className = `navLink ${isActive ? 'active' : ''}`;
    return className;
  };
  return (
    <div className="header">
      <img src={logo} className="logo" alt="" />
      <div className="menu">
        <NavLink to="/" className={getLinkClass}>
          <Tab label="Inicio" selected />
        </NavLink>
        <NavLink to="/reviews" className={getLinkClass}>
          <Tab label="Historias de viaje" selected />
        </NavLink>
      </div>
    </div>
  );
}

export default NavBar;
