import { Box } from '@mui/material';
import React from 'react';
import '../styles/StoriesPage.css';
import contract from '../data/contracts/countries.json';
import Factory from '../Factory';

function StoriesPage() {
  return (
    <div className="storiesList">
      <Box sx={{ display: 'flex' }}>
        <Factory contract={contract} />
      </Box>
    </div>
  );
}

export default StoriesPage;
