import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grow } from '@mui/material';
import CardBox from './components/CardBox';
import recursiveRender from './RecursiveRender';
import TextBox from './components/TextBox';
import Thumbnail from './components/Thumbnail';

function getComponent(name) {
  return {
    CardBox,
    Link,
    Box,
    Grow,
    TextBox,
    Thumbnail,
  }[name];
}
function Factory({ contract }) {
  return <>{recursiveRender(getComponent, contract)}</>;
}

export default Factory;
