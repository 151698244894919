/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import '../styles/Signup.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import countries from '../data/countries.json';
import scubaLevels from '../data/scubaLevels.json';
import certAgencies from '../data/certAgencies.json';

function Signup() {
  const [loading, isLoading] = useState(false);
  const [country, setCountry] = useState('Colombia');
  const [scubaLevel, setScubaLevel] = useState('');
  const [certAgency, setCertAgency] = useState('');
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    name: '',
    lastName: '',
    email: '',
    scubaLevel: '',
    certAgency: '',
    country: 'Colombia',
    city: '',
    password: '',
    tyc: false,
  });
  function setFieldValue(event) {
    setFields((fields) => {
      fields[event.target.id] = event.target.value;
      return fields;
    });
  }
  function tycChange({ target }) {
    setFields((fields) => {
      fields.tyc = target.checked;
      return fields;
    });
  }
  function countryChange({ target }) {
    setFields((fields) => {
      fields.country = target.value;
      setCountry(target.value);
      return fields;
    });
  }
  function scubaLevelChange({ target }) {
    setFields((fields) => {
      fields.scubaLevel = target.value;
      setScubaLevel(target.value);
      return fields;
    });
  }
  function certAgencyChange({ target }) {
    setFields((fields) => {
      fields.certAgency = target.value;
      setCertAgency(target.value);
      return fields;
    });
  }
  function getCountriesList() {
    const countriesList = [];
    for (const index in countries) {
      countriesList.push(
        <MenuItem key={index} value={countries[index]}>
          {countries[index]}
        </MenuItem>,
      );
    }
    return countriesList;
  }
  function getScubaLevelsList() {
    const scubaLevelsList = [];
    for (const index in scubaLevels) {
      scubaLevelsList.push(
        <MenuItem key={index} value={scubaLevels[index]}>
          {scubaLevels[index]}
        </MenuItem>,
      );
    }
    return scubaLevelsList;
  }
  function getCertAgenciesList() {
    const certAgenciesList = [];
    for (const index in certAgencies) {
      certAgenciesList.push(
        <MenuItem key={index} value={certAgencies[index]}>
          {certAgencies[index]}
        </MenuItem>,
      );
    }
    return certAgenciesList;
  }
  async function createUser(e) {
    e.preventDefault();
    try {
      isLoading(true);
      const response = await fetch('https://api.1000dives.com/user', {
        method: 'PUT',
        body: JSON.stringify(fields),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      await response.json();
      navigate('/signupcompleted');
    } catch (err) {
      navigate('/error');
    }
  }
  return (
    <Grow in timeout={500} className="signup">
      <form onSubmit={createUser}>
        <FormGroup>
          <TextField
            required
            id="email"
            label="Correo"
            size="small"
            variant="outlined"
            onChange={setFieldValue}
          />
          <TextField
            id="name"
            label="Nombre"
            size="small"
            variant="outlined"
            onChange={setFieldValue}
          />
          <TextField
            id="lastName"
            label="Apellidos"
            size="small"
            variant="outlined"
            onChange={setFieldValue}
          />
          <FormControl required>
            <InputLabel>Escalafón de buceo</InputLabel>
            <Select id="scubaLevel" value={scubaLevel} onChange={scubaLevelChange}>
              {getScubaLevelsList()}
            </Select>
          </FormControl>
          <FormControl required>
            <InputLabel>País</InputLabel>
            <Select id="country" value={country} onChange={countryChange}>
              {getCountriesList()}
            </Select>
          </FormControl>
          <FormControl required>
            <InputLabel>Agencia de certificación</InputLabel>
            <Select id="certAgency" value={certAgency} onChange={certAgencyChange}>
              {getCertAgenciesList()}
            </Select>
          </FormControl>
          <TextField
            id="city"
            label="Ciudad"
            size="small"
            variant="outlined"
            onChange={setFieldValue}
          />
          <TextField
            id="password"
            label="Contraseña"
            size="small"
            variant="outlined"
            type="password"
            autoComplete="current-password"
            onChange={setFieldValue}
          />
          <FormControlLabel
            required
            id="tyc"
            control={<Checkbox />}
            label="Términos y condiciones"
            onChange={tycChange}
          />
          <Box className="submit">
            <Button variant="contained" disabled={loading} type="submit">
              Regístrate
            </Button>
            {loading && <CircularProgress className="loading" size={24} />}
          </Box>
        </FormGroup>
      </form>
    </Grow>
  );
}
export default Signup;
