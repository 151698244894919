import React from 'react';
import { Box } from '@mui/material';
import '../styles/CityPage.css';
import contract from '../data/contracts/reviews.json';
import Factory from '../Factory';

function CityPage() {
  return (
    <div className="cities">
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Factory contract={contract} />
      </Box>
    </div>
  );
}
export default CityPage;
