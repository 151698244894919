import React from 'react';

function recursiveRender(getComponent, components = []) {
  const renderComponent = (currentComponent, index) => {
    const {
      id, type, components: childComponents, config,
    } = currentComponent;

    const Component = getComponent(type);

    return Component ? (
      <Component key={`${id}-${index.toString()}`} {...config}>
        {childComponents ? recursiveRender(getComponent, childComponents) : null}
      </Component>
    ) : null;
  };
  const allcomponents = components.map(renderComponent);
  return allcomponents.length > 1 ? allcomponents : allcomponents[0];
}

export default recursiveRender;
