import React from 'react';
import '../styles/Footer.css';
import instagramLogo from '../images/instagram.svg';

function Footer() {
  return (
    <div className="footer">
      <a href="https://www.instagram.com/1000dives/" target="_blank" rel="noreferrer">
        <img className="social-media icon" src={instagramLogo} alt="" />
      </a>
    </div>
  );
}

export default Footer;
