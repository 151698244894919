import colombia from './flags/co.svg';
import mexico from './flags/mx.svg';
import instagram from './instagram.svg';
import logo from './logo.svg';
import santaMarta from './santaMarta.jpg';
import cancun from './cancun.jpg';
import cdmx from './cdmx.jpg';
import buceotest from './buceotest.jpg';
import sanAndres from './sanAndres.jpg';
import tayronaDiveCenter from './tayronaDiveCenter.jpg';

const exporting = {
  colombia,
  instagram,
  logo,
  santaMarta,
  mexico,
  cancun,
  cdmx,
  buceotest,
  sanAndres,
  tayronaDiveCenter,
};

export default exporting;
