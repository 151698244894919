import React from 'react';
import { Box } from '@mui/material';
import contract from '../data/contracts/cities.json';
import '../styles/CountryPage.css';
import Factory from '../Factory';

function CountryPage() {
  return (
    <div className="cities">
      <Box sx={{ display: 'flex' }}>
        <Factory contract={contract} />
      </Box>
    </div>
  );
}
export default CountryPage;
