import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Body from '../components/Body';
import Footer from '../components/Footer';
import '../styles/Root.css';

function RootLayout() {
  return (
    <div className="root">
      <NavBar />
      <Body>
        <Outlet />
      </Body>
      <Footer />
    </div>
  );
}
export default RootLayout;
