import React from 'react';
import '../styles/Root.css';

function Error() {
  return (
    <div className="error">
      <h1>Ha ocurrido un error inesperado</h1>
    </div>
  );
}
export default Error;
