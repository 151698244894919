import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './pages/Root';
import StoriesPage from './pages/StoriesPage';
import About from './components/About';
import CountryPage from './pages/CountryPage';
import CityPage from './pages/CityPage';
import ReviewPage from './pages/ReviewPage';
import Signup from './pages/Signup';
import SignupCompleted from './pages/SignupCompleted';
import Error from './pages/Error';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: '/', element: <About /> },
      { path: '/error', element: <Error /> },
      { path: '/reviews', element: <StoriesPage /> },
      { path: '/reviews/:country', element: <CountryPage /> },
      { path: '/reviews/:country/:city', element: <CityPage /> },
      { path: '/reviews/:country/:city/:reviewId', element: <ReviewPage /> },
      { path: '/signup', element: <Signup /> },
      { path: '/signupcompleted', element: <SignupCompleted /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
