import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import '../styles/CardBox.css';
import images from '../images';

function CardBox({ title, image, className = '' }) {
  return (
    <div className={`${className} card`}>
      <Card>
        <CardActionArea>
          {image && <CardMedia component="img" image={images[image]} alt={title} />}
          <CardContent>
            <Typography variant="h7" align="center">
              {title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default CardBox;
