import {
  Button,
  FormGroup,
  Grow,
  Typography,
} from '@mui/material';
import '../styles/SignupCompleted.css';
import { Link } from 'react-router-dom';

function SignupCompleted() {
  return (
    <Grow in timeout={500} className="signup-completed">
      <FormGroup>
        <Typography>¡Gracias por registrarte!</Typography>
        <br />
        <Link to="/">
          <Button variant="outlined">Finalizar</Button>
        </Link>
      </FormGroup>
    </Grow>
  );
}
export default SignupCompleted;
